import angular from 'angular';
import './pp/kohde-app';
import './pp/vuosi-app';
const applistTemplate = require('./mgmt/applications/app-list.tpl.html');
angular.module('tkr.custom')
    .decorator('customizationConfig', CustomizationConfigDecorator);
CustomizationConfigDecorator.$inject = ['$delegate'];
function CustomizationConfigDecorator($delegate) {
    const cc = $delegate;
    cc.prcListDetailTemplate = require('./mgmt/prc/prc-list.tpl.html');
    cc.getAppListTemplate = (appPeriod) => {
        return applistTemplate;
    };
    return cc;
}
