import angular from 'angular';
import ngMessages from 'angular-messages';
import moment from 'moment';
import ngTranslate from 'angular-translate';
import 'angular-translate-loader-static-files';
import TkrSpaCommon from '../../../common/tkr-spa';
import { TkrPersonService } from '../../../common/tkr-commonservices';
import { IbanService, IbanValidator } from '../../../common/ma-iban';
import { SmartFloat, SmartInteger } from '../../../common/ma-smartfloat-1.0';
import TkrPpCommon from '../../../pp/common/0-pp-common-module';
import TkrPpResources from '../../../pp/prcportal-resource-modules';
import { configureTranslateProvider } from '../../../pp/translation-helpers';
const subsidy_application_app = angular.module('ng-tkr-app-sastamalayhdkyla', [
    TkrSpaCommon,
    ngMessages,
    ngTranslate,
    TkrPersonService,
    IbanValidator,
    IbanService,
    SmartFloat,
    SmartInteger,
    TkrPpResources,
    TkrPpCommon,
])
    .config(config)
    .config(configureTranslateProvider);
angular.module('ng-tkr-app-sastamalayhdnuoriso', ['ng-tkr-app-sastamalayhdkyla']);
angular.module('ng-tkr-app-sastamalayhdkulttuuri', ['ng-tkr-app-sastamalayhdkyla']);
angular.module('ng-tkr-app-sastamalayhdelake', ['ng-tkr-app-sastamalayhdkyla']);
angular.module('ng-tkr-app-sastamalayhdsosiaali', ['ng-tkr-app-sastamalayhdkyla']);
config.$inject = ['$stateProvider', '$urlRouterProvider'];
function config($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise('/err');
    $stateProvider
        .state('err', {
        url: '/err',
        template: '<p class="h4 text-danger">Sisäinen virhe. Ota yhteyttä helpdesk@matriset.fi.</p>'
    })
        .state('root', {
        url: '',
        template: '',
        controller: 'AppRedirCtrl',
        controllerAs: 'vm'
    })
        .state('lomake', {
        url: '/:applicationId',
        templateProvider: ['appPeriod', function (appPeriod) {
                if (appPeriod.variant == 'sastamalayhdnuoriso') {
                    return require('./vuosi-nuoriso-app.tpl.html');
                }
                else if (appPeriod.variant == 'sastamalayhdkulttuuri') {
                    return require('./vuosi-kulttuuri-app.tpl.html');
                }
                else if (appPeriod.variant == 'sastamalayhdelake') {
                    return require('./vuosi-elake-app.tpl.html');
                }
                else if (appPeriod.variant == 'sastamalayhdsosiaali') {
                    return require('./vuosi-sosiaali-app.tpl.html');
                }
                else if (appPeriod.variant == 'sastamalayhdkyla') {
                    return require('./vuosi-kyla-app.tpl.html');
                }
                return undefined;
            }],
        controller: 'SubsidyApplicationController',
        controllerAs: 'vm',
        resolve: {
            app: ['PortalSubsidyAppService', '$stateParams', function (PortalSubsidyAppService, $stateParams) {
                    return PortalSubsidyAppService.get({ appId: $stateParams.applicationId }).$promise;
                }],
            appPeriod: ['PortalAppPeriodService', 'app', function (PortalAppPeriodService, app) {
                    return PortalAppPeriodService.get({ periodId: app.applicationPeriodId }).$promise;
                }]
        }
    })
        .state('jatetty', {
        url: '/:applicationId/jatetty',
        template: require('./vuosi-submitted.tpl.html'),
        controller: 'AppSubmittedController',
        controllerAs: 'vm'
    });
}
subsidy_application_app.controller('SubsidyApplicationController', SubsidyApplicationController);
SubsidyApplicationController.$inject = [
    '$scope',
    'TkrConfig',
    'app',
    'appPeriod',
    'TkrPersonService',
    '$state',
];
function SubsidyApplicationController($scope, TkrConfig, app, appPeriod, TkrPersonService, $state) {
    var vm = this;
    vm.app = app;
    vm.vuosi = appPeriod.td.wnum.vuosi;
    vm.edvuosi = vm.vuosi - 1;
    vm.loppuAika = moment(appPeriod.end).local().format('D.M.YYYY HH:mm');
    vm.periodName = appPeriod.name;
    vm.personRoles = TkrPersonService.personRoles;
    vm.chairmanRoles = TkrPersonService.chairmanRoles;
    vm.noAttachmentHtml = '<p class="text-danger"><strong>HUOM! Hakemuksella ei ole liitteitä.</strong> Liitteiden puuttuminen johtaa hakemuksen hylkäämiseen.</p>';
    vm.attachmentDefs = [
        { type: 'tilinpaatos', name: 'Vuoden ' + vm.edvuosi + ' tilinpäätös', required: true },
        { type: 'toimintakertomus', name: 'Vuoden ' + vm.edvuosi + ' toimintakertomus', required: true },
        { type: 'toiminnantarkastuskertomus', name: 'Vuoden ' + vm.edvuosi + ' toiminnantarkastuskertomus', required: true },
        { type: 'toimintasuunnitelma', name: 'Vuoden ' + vm.vuosi + ' toimintasuunnitelma', required: true },
        { type: 'talousarvio', name: 'Vuoden ' + vm.vuosi + ' talousarvio', required: true }
    ];
    if (appPeriod.variant === 'sastamalayhdliikunta' || appPeriod.variant === 'sastamalayhdelake' || appPeriod.variant === 'sastamalayhdsosiaali') {
        vm.attachmentDefs.splice(3, 0, { type: 'toimintakuvaus', name: 'Vuoden ' + vm.edvuosi + ' toimintaa kuvaava liite', required: true });
    }
    vm.preprocessApp = function (data) {
        if (!data.chairman) {
            data.chairman = {};
        }
        if (!data.contactPerson) {
            data.contactPerson = {};
        }
        if (!data.td) {
            data.td = {};
        }
        if (!data.td.num) {
            data.td.num = {};
        }
        if (!data.td.wnum) {
            data.td.wnum = {};
        }
    };
    vm.preprocessApp(vm.app);
    $scope.$watch('app.contactPerson.role', function (newValue, oldValue) {
        $scope.contactIsChairman = TkrPersonService.isValidChairmanRole(newValue);
    });
    $scope.$watch('contactIsChairman', function (newValue, oldValue) {
        if (!newValue) {
            if ($scope.old_chairman) {
                vm.app.chairman = $scope.old_chairman;
            }
        }
        else if (newValue && !oldValue) {
            $scope.old_chairman = vm.app.chairman;
            vm.app.chairman = vm.app.contactPerson;
        }
    });
    vm.isChairmanRequired = function () {
        return true;
    };
    vm.isSeparateChairmanRequired = function () {
        if (!vm.isChairmanRequired()) {
            return false;
        }
        if (!vm.app || !vm.app.contactPerson) {
            return true;
        }
        return TkrPersonService.isValidChairmanRole(vm.app.contactPerson.role) ? false : true;
    };
    vm.postprocessApp = function (data) {
        switch (data.contactPerson.role) {
            case TkrPersonService.PJ_ROLE_VALUE:
                data.chairman = data.contactPerson;
                break;
            case TkrPersonService.RH_ROLE_VALUE:
                data.people.rh = data.contactPerson;
                break;
            case TkrPersonService.SIHTEERI_ROLE_VALUE:
                data.people.siht = data.contactPerson;
                break;
            default:
                break;
        }
        data.chairman.role = TkrPersonService.PJ_ROLE_VALUE;
        data.people.rh.role = TkrPersonService.RH_ROLE_VALUE;
        data.people.siht.role = TkrPersonService.SIHTEERI_ROLE_VALUE;
        if (!data.td.num.avustusEd) {
            data.td.text.edAvustusKaytto = '';
        }
    };
    vm.save = function () {
        vm.postprocessApp(vm.app);
        vm.app.applicationState = 'SUBMITTED';
        vm.app.$save().then(function (data) {
            $state.go('jatetty', { applicationId: data.id });
        });
    };
    vm.attachmentsValidCb = function (status) {
        vm.requiredAttachmentsPresent = status ? true : undefined;
    };
    vm.dataFileUrl = function (fileName) {
        return TkrConfig.aUrl(`resources/${fileName}`);
    };
    vm.saveDraft = function () {
        vm.app.applicationState = 'DRAFT';
        vm.app.$save().then(function (data) {
            vm.draftSaved = true;
        });
    };
}
